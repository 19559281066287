import React, { useEffect } from 'react';
import { TVChartContainer } from './componentes/TVChartContainer/index';
import { useTranslation } from "react-i18next";
import DetalleOperaciones from './componentes/Details'
import InfoCuenta from '../../components/InfoCuenta';
import Modal from 'react-modal';
import ModelNewOperation from './componentes/modelNewOperation';
import './Graficas.css';
import { useDispatch } from 'react-redux';
import { showAlertAccion, closeAlertAccion } from '../../redux/alert_webtrade';
import modalStyles from './modalStyles';
import { callSymbols } from '../../config/helper';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"; /* Agregamos la libreria para las rutas */

    function Graficas() {
        const store = useSelector(store => store)
        const cuenta = store.informacionUsuario.accounts.demo && store.informacionUsuario.accounts.live ? true:false; 
        useEffect(() => {
            const callSymbolList = async() => {
                sessionStorage.removeItem("symbolList");
                const symbolList = await callSymbols();
                const value = JSON.stringify(symbolList.body.listSymbols);
                sessionStorage.setItem("symbolList", value);
                setTimeout(function(){
                    setGrafic(1);
                },500);
                
            }
            callSymbolList()
        }, []);

        const dispatch = useDispatch();
        const navigate = useNavigate();

        const openAlert = (message,typeAlert) =>{
            dispatch(showAlertAccion({message,typeAlert}));
        }
    
        const closeAlert = () =>{
            setTimeout(() => {
                dispatch(closeAlertAccion());
            },3000);
        }

    Modal.setAppElement('#root');
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const { t } = useTranslation();
    const [symbol,setSymbol] = React.useState('FOREX:C:EURUSD');
    const [stopLoss,setStopLoss] = React.useState(0);
    const [takeProfit,setTakeProfit] = React.useState(0);
    const [grafic,setGrafic] = React.useState(0);

    const asignarDatos = (data) =>{
        setSymbol(data.symbol);
        setStopLoss(data.stopLoss);
        setTakeProfit(data.takeProfit);
        setGrafic(0);
        setTimeout(function(){
            setGrafic(1);
        },500);
    }

    const newOperation = () =>{
        closeModal();
        showAlert(t('mt4.new_operation_success'))
    }
    
    const showAlert = (message) => {
        openAlert(message,'success');
        closeAlert();
    }


    return (
        <React.Fragment>
            <div className='text-start row'>
                <div className="col-12 col-md-2">
                    {cuenta?(<center>{grafic == 0 ? null:<button className='btn btn-primary mb-2 gen-button' onClick={openModal}> {t('mt4.new_trade')} </button>}</center>):(
                    <center><button className='btn btn-primary mb-2 gen-button' onClick={() => navigate(`/profile`)}> {t('mt4.create_account')} </button></center>)}
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={modalStyles}
                        id="App"
                    >
                        <ModelNewOperation closeModal={()=>newOperation()}/>
                        {/* <button className="btn btn-danger mt-3" onClick={closeModal}> {t('mt4.modal.button-close')} </button> */}
                        <div id="alert" className="alert alert-success fade" role="alert">
                        </div>
                    </Modal>
                </div>
                <div className="col-12 col-md-10"><InfoCuenta sizeCantidad='1.5' sizeTiulo='1.2' /></div>
            </div>
            <hr />
            { grafic == 0 ? null:(<TVChartContainer stopLoss={stopLoss} takeProfit={takeProfit} information={symbol} symbol={`${symbol}`} interval="1" theme="white"/>) }
            <DetalleOperaciones changeSimbol={(data) => asignarDatos(data)} useAlert={(data) => showAlert(data)} />
        </React.Fragment>
    );
}

export default Graficas;