import React from 'react';
import Item from './components/Item';
import { useTranslation } from "react-i18next";
import './style.css';
import { Link, useNavigate } from "react-router-dom"; /* Agregamos la libreria para las rutas */

function NavBar() {
	const { t } = useTranslation();

  const openClose = () => {
    const sidebar = document.querySelector(".sidebar");

    sidebar.classList.toggle("close");
    
  }

  return (
    <React.Fragment>    
      <nav className="sidebar close no-imprimir">
        <header>
          <div className="image-text">
            <span className="image">
								{/* <img src="/img/logotipo.png" alt="" /> */}
                <box-icon size='md' color='#7d0612' onClick={() => openClose()} name='menu'></box-icon>
            </span>
          </div>
          <div onClick={() => openClose()} className="toggle">
            <box-icon name='left-arrow'></box-icon>
          </div>
        </header>
        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links">
              <Item to='/' icon='home-alt-2' name='Dashboard' type='link' />
              <Item to='profile' icon='user-circle' name='Mi Perfil' type='link' />
              <Item to='deposit' icon='wallet' name='Depositar Fondos' type='link' />
              <Item to='retiros' icon='dollar-circle' name='Retirar Fondos' type='link' />
              <Item to='/grafic/*' icon='candles' name='Mis Inversiones' type='link' />
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default NavBar;