import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import Cookies from 'universal-cookie';
import { tradeInformation, newOperation, callSymbols} from '../../../config/helper';
import { useSelector } from 'react-redux';
import './modelNewOperation.css';
import Loading from '../../../components/Loading';
import InputFilter from './InputFilter';

function modelNewOperation({closeModal}) {

	const { t } = useTranslation();
    const cookies = new Cookies();
	const store = useSelector(store => store)
    
    const [symbolSelect,setSymbolSelect] = React.useState('');
    // const [symbolList,setSymbolList] = React.useState([]);
    const [options,setOptions] = React.useState([]);
    const [symbol,setSymbol] = React.useState(0);
    const [volumen,setVolumen] = React.useState(0.01);
    const [stopLoss,setStopLoss] = React.useState(0);
    const [takeProfit,setTakeProfit] = React.useState(0);
    // const [type,setType] = React.useState(0);
    // const [comment,setComment] = React.useState(0);
    const [buyPrice,setBuyPrice] = React.useState(0);
    const [sellPrice,setSellPrice] = React.useState(0);
    const [loadingShow,setLoadingShow] = React.useState(false);
    const [alertFilterShow,setAlertFilterShow] = React.useState(false);
    
    useEffect(() => {
        let simbol = cookies.get('parsedSymbol', { path: '/'});
		setSymbolSelect(simbol);
        tradeInformationF(simbol);
    }, []);

    const cantidadesCero = () => {
        setBuyPrice(0);
        setSellPrice(0);
    }

    useEffect(() => {
        let simbol = symbolSelect;
        const interval = setInterval(() => {
            if(symbolSelect){
                tradeInformationF(simbol);
            }else{
                cantidadesCero();
            }
        }, 1000);
      
        // Limpia el intervalo cuando el componente se desmonta o cuando se actualiza la dependencia
        return () => {
          clearInterval(interval);
        };

	},[symbolSelect]);

    useEffect(() => {
        const listado = JSON.parse(sessionStorage.getItem("symbolList"));

        const filtered = listado.filter(option =>
        typeof option.symbol === "string" && option.symbol.indexOf(symbolSelect) === 0
        ).slice(0, 5);

        if(filtered.length === 0){
            setOptions([]);
            setAlertFilterShow(true)
            cantidadesCero()
        }else if(filtered.length === 1){
            filtered[0].symbol === symbolSelect ? setOptions([]):setOptions(filtered);
            setAlertFilterShow(false)
        }else{
            setOptions(filtered);
            setAlertFilterShow(false)
        }

	},[symbolSelect]);

    const tradeInformationF = async(data) => {
        console.log('ingrso')
        try {
            const { body, message, error } = await tradeInformation({symbol:data})
            if(message == 'Successful'){
                setAlertFilterShow(false)
                if(body.buy){
                    setBuyPrice(body.buy);
                }
                if(body.sell){
                    setSellPrice(body.sell);
                }
            }else{
                cantidadesCero()
            }
        } catch (error) {
            console.log('error',error)
            cantidadesCero()
        }
    }

    const createOperation = async(operation) =>{
        try {
            const sellButton = document.getElementById('sell');
            const buyButton = document.getElementById('buy');
            const spread = sellPrice >= buyPrice ? (sellPrice - buyPrice):(buyPrice - sellPrice);
            sellButton.disabled = true;
            buyButton.disabled = true;
            setLoadingShow(true)
            
            let symbols = symbol === 0 ? symbolSelect:symbol
            let price =  operation === 'buy' ? buyPrice:sellPrice;
            
            let cuenta = store.informacionUsuario.accounts[store.informacionUsuario.role]; 
            
            const data = {
                    account:cuenta,
                    operation:operation,
                    symbol: symbols,
                    volumen,
                    price,
                    stopLoss,
                    takeProfit,
                    spread
                };
                
                const { message, body, error } = await newOperation(data);
    
                if(message === 'Successful'){
                    sellButton.disabled = false;
                    buyButton.disabled = false;
                    setLoadingShow(false)
                    closeModal();
                }else{
                    sellButton.disabled = false;
                    buyButton.disabled = false;
                    setLoadingShow(false)
                    console.log('sucedio un error',error)
                    const alert = document.querySelector('#alert-model');
                    alert.textContent = `Error! No ha sido posible registrar la Operacion, ${error}.`;
                    alert.classList.replace('alert-success', 'alert-danger');
                    alert.classList.add('show');
                    setTimeout(() => {
                        alert.classList.remove('show');
                    }, 3000);
                }

        } catch (error) {
            console.log('sucedio un error')
            const alert = document.querySelector('#alert-model');
            alert.textContent = `Error! ${error} .`;
            alert.classList.replace('alert-success', 'alert-danger');
            alert.classList.add('show');
        }
    }

    const SelectSymbol = (symbol) => {
        setSymbolSelect(symbol);
        setOptions([]);
    }

    const stopLossSet = (value) => {
        if(stopLoss === 0){
            setStopLoss(sellPrice);
        }else{
            setStopLoss(value);
        }
    }

    const  takeProfitSet = (value) => {
        if(takeProfit === 0){
            setTakeProfit(sellPrice);
        }else{
            setTakeProfit(value);
        }
    }

    return (
        <React.Fragment>
            <center><h1 className="gen-fontwhite"> {t('mt4.modal.title')} </h1></center>
            <hr />
            <div className="row form-model">
                {/* <div className="col-12">
                    <InputFilter options={options} symbolSelect={symbolSelect} setSymbolSelect={(data)=>setSymbolSelect(data)} />
                </div> */}
                <label htmlFor="symbol"> {t('mt4.modal.symbol')} </label><br />
                <div className="row col-12 input-filter">
                    <div className="col-7">
                        <input type="text" className="" list="myList"  value={symbolSelect} onChange={e => setSymbolSelect(e.target.value.toUpperCase())}/>
                        <div className="select-symbol">
                            <ul>
                                {options.map(item => (
                                    <li key={item.symbol} onClick={() => SelectSymbol(item.symbol)}>{item.symbol} - {item.description}</li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-5">
                        {
                            alertFilterShow ? <span>Sin Coincidencias</span>:null
                        }
                        
                    </div>
                </div>
                <div className="col-12">
                    <label htmlFor="volumen"> {t('mt4.modal.volumen')} </label><br />
                    {/* <input className="form-control gen-input-text" type="number" name="volumen" value={volumen} onChange={e => setVolumen(e.target.value)} /> */}
                    <select className="form-control gen-input-text" name="volumen" onChange={e => setVolumen(e.target.value)} >
                        <option key={0.01} defaultValue={0.01} >0.01</option>
                        <option key={0.02} value={0.02}>0.02</option>
                        <option key={0.03} value={0.03}>0.03</option>
                        <option key={0.04} value={0.04}>0.04</option>
                        <option key={0.05} value={0.05}>0.05</option>
                        <option key={1.00} value={1.00}>1.00</option>
                        <option key={2.00} value={2.00}>2.00</option>
                        <option key={3.00} value={3.00}>3.00</option>
                        <option key={4.00} value={4.00}>4.00</option>
                        <option key={5.00} value={5.00}>5.00</option>
                        <option key={6.00} value={6.00}>6.00</option>
                        <option key={7.00} value={7.00}>7.00</option>
                        <option key={8.00} value={8.00}>8.00</option>
                    </select>
                </div><div className="col-6">
                    <label htmlFor="sl"> {t('mt4.modal.s-l')} </label><br />
                    <input className="form-control gen-input-text" type="number" step="0.00001"  name="sl" value={stopLoss} onChange={e => stopLossSet(e.target.value)} />
                </div><div className="col-6">
                    <label htmlFor="tp"> {t('mt4.modal.t-p')} </label><br />
                    <input className="form-control gen-input-text" type="number" step="0.00001"  name="tp" value={takeProfit} onChange={e => takeProfitSet(e.target.value)} />
                </div><div className="col-12">
                    <label htmlFor="type"> {t('mt4.modal.type')} </label><br />
                    <select style={{display:'none'}} className="form-control gen-input-text" name="type" onChange={e => setType(e.target.value)} >
                        <option value="AAPL">Market Execution</option>
                        <option value="NTFL">Pending Order</option>
                    </select>
                </div>
                {/* <div className="col-12">
                    <label htmlFor="comment"> {t('mt4.modal.comment')} </label><br />
                    <textarea className="form-control" name="comment" onChange={e => setComment(e.target.value)} />
                </div> */}
            </div>
            <div className='row option-operation'>
                <div className="col-5">
                    <h4 className="gen-text-color-prices">{ sellPrice.toFixed(6) }</h4>
                    <button id='sell' className="btn mt-3 me-1 sell" onClick={() => createOperation('sell')}> {t('mt4.modal.button-sell')} </button>
                </div>
                <div className="col-2 diagonal">/</div>
                <div className="col-5">
                    <h4 className="gen-text-color-prices">{ buyPrice.toFixed(6) }</h4>
                    <button id='buy' className="btn mt-3 me-1 buy" onClick={() => createOperation('buy')}> {t('mt4.modal.button-buy')} </button>
                </div>
            </div>
            <div id="alert-model" className="alert alert-danger fade mt-2" role="alert"></div>
            <div>
                <Loading show={loadingShow} />
            </div>
        </React.Fragment>
    );
}

export default modelNewOperation;