import React from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { Link, Routes, Route, useNavigate } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import WithdrawalsTarjeta from './WithdrawalsTarjeta';
import WithdrawalsBanco from './WithdrawalsBanco';
import '../Fondos';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function withdrawals() {
	const { t } = useTranslation();
    const navigate = useNavigate();

    const withdrawType = (type) => {
        console.log(type);
        navigate(`/retiros/${type}`)
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-7">
                    <div className="row">
                        <label htmlFor="withdrawType" className="form-label"> {t('fondos.withdrawals.withdrawals_form')} </label>
                        {/* <Link to="bancaria" className="col-6 text-center optionTitle"><h4> {t('fondos.withdrawals.banco')} </h4></Link> */}
                        {/* <Link to="tarjeta" className="col-6 text-center optionTitle"><h4> {t('fondos.withdrawals.tarjeta')} </h4></Link> */}
                        <select className='form-control gen-input-text' name="withdrawType" id="withdrawType" onChange={(event) => withdrawType(event.target.value)}>
                            <option value="bancaria">{t('fondos.withdrawals.banco')}</option>
                            <option value="tarjeta">{t('fondos.withdrawals.tarjeta')}</option>
                        </select>
                        <div className="col-12">
                            <Routes>
                                <Route path="/" element={<WithdrawalsBanco sendData={ (data) => sendData(data) } />} />
                                <Route path="/bancaria" element={<WithdrawalsBanco sendData={ (data) => sendData(data) } />} />
                                <Route path="/tarjeta" element={<WithdrawalsTarjeta sendData={ (data) => sendData(data) } />} />
                            </Routes>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 text-start">
                    <h4> {t('fondos.withdrawals.required_documents')} </h4>
                    <ul>
                        <li> {t('fondos.withdrawals.required_documents_list.one')} </li>
                        <li> {t('fondos.withdrawals.required_documents_list.two')} </li>
                        <li> {t('fondos.withdrawals.required_documents_list.three')} </li>
                    </ul>
                    <p className="text-start"> {t('fondos.withdrawals.paragraph_one')} </p>
                    <p> {t('fondos.withdrawals.paragraph_two')} </p>
                    <p> {t('fondos.withdrawals.paragraph_three')} </p>
                    <h6> {t('fondos.withdrawals.h6')} </h6>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withdrawals;