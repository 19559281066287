// const linkBackend = `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}`
const linkBackend = process.env.REACT_APP_BACKEND_IP
const linkBackendLeads = process.env.REACT_APP_BACKEND_LEADS

import { GET, POST } from './conect';

export async function logout(){return GET(`${linkBackend}/auth/logout`)};

export async function getInfo(){return GET(`${linkBackend}/users/information-account`)};

export async function tradeInformation(data){return POST(`${linkBackend}/invest/tradeInformation`,data)};

export async function newOperation(data){return POST(`${linkBackend}/invest/newOperation`,data)};

export async function closeOperation(data){return POST(`${linkBackend}/invest/closeOperation`,data)};

export async function getHistoryData(data){return POST(`${linkBackend}/information/getHistoryData`,data)};

// APIS PARA LA MT4 Y FUNCIONAMIENTO DE LA GRAFICO DE TRADINGVIEW

export async function callSymbols(){return GET(`${linkBackend}/tradeview/callSymbols`)};

export async function callBars(data){return POST(`${linkBackend}/tradeview/callBars`,data)};

// APIS PARA CABINET

export async function login(data){return await POST(`${linkBackendLeads}/cabinet/login`, data)};

export async function getInformation(){return GET(`${linkBackendLeads}/cabinet/get-information`)};

export async function getUserInformation(){return GET(`${linkBackendLeads}/cabinet/get-user-information`)};

export async function updateUserInformation(data){return POST(`${linkBackendLeads}/cabinet/update-user-information`, data)};

export async function createUserInformation(data){return POST(`${linkBackendLeads}/cabinet/create-user-information`, data)};

export async function changePasswordApi(data){return POST(`${linkBackendLeads}/cabinet/change-password`, data)};

export async function getAccountInformation(data){return POST(`${linkBackendLeads}/account/get-account-information`, data)};

export async function getInfoComplete(){return GET(`${linkBackendLeads}/users/information-complete`)};

export async function getGeneralInformation(){return GET(`${linkBackendLeads}/information`)};

export async function updateGeneralInformation(data){return POST(`${linkBackendLeads}/users/update-information`,data)};

export async function getAccount(data){ return POST(`${linkBackendLeads}/users/get-account`, data)};

export async function investInformation(data){return POST(`${linkBackendLeads}/users/invest`,data)};

export async function newWithdrawals(data){return POST(`${linkBackendLeads}/operations/withdrawals`,data)};

export async function newDeposit(data){return POST(`${linkBackendLeads}/operations/deposits`,data)};

export async function saveNewLead(data){return POST(`${linkBackendLeads}/leads/register`,data)};