import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import InfoCuenta from '../components/InfoCuenta';
import { getHistoryData } from '../config/helper'
import Chartjs from '../components/chartjs/Chartjs';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import Loading from '../components/Loading';

function Inicio() {

	const { t } = useTranslation();
  const store = useSelector(store => store)
  const navigate = useNavigate();
  const [cuenta, setCuenta] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chartjsData, setChartjsData] = useState([[{ base: 0, count: 0 }]]);

  const transformarArreglo = (body) => {
      let dataNuew = {};
      let deposit = []
      
      let totCantidad = 0
      body.forEach(element => {
          totCantidad += element.amount
          deposit.push({base:element.createdAt,count:totCantidad});
        });
          dataNuew = {
              labels: deposit.map((data) => data.base), 
              datasets: [
                {
                  label: "Users Gained ",
                  data: deposit.map((data) => data.count),
                  backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0"
                  ],
                  borderColor: "red",
                  borderWidth: 2
                }
              ]
            }
        setChartData(dataNuew);
        setLoading(false)
    }

    const updateData = async(pageNumberVar = 1) => {
      setLoading(true)
      if(store.informacionUsuario.accounts.demo && store.informacionUsuario.accounts.live){
        const account = store.informacionUsuario.accounts[store.informacionUsuario.role]
        const pageSize = 0
        const sort = 0
        const { body } = await getHistoryData({account,pageNumber:pageNumberVar,pageSize,sort})
        transformarArreglo(body)
      }
    }

    useEffect(() => {
      if(store.informacionUsuario.accounts.demo && store.informacionUsuario.accounts.live){
        setCuenta(true)
        updateData();
      }
    },[])

    useEffect(() => {
      updateData();
    },[store.investmentInfoAccount.equity, store.informacionUsuario.role])

    const [chartData, setChartData] = useState({
        labels: chartjsData.map((data) => data.base), 
        datasets: [
          {
            label: "Users Gained ",
            data: chartjsData.map((data) => data.count),
            backgroundColor: [
              "rgba(75,192,192,1)",
              "#ecf0f1",
              "#50AF95",
              "#f3ba2f",
              "#2a71d0"
            ],
            borderColor: "red",
            borderWidth: 5
          }
        ]
      });


    return (
        <React.Fragment>
            <InfoCuenta sizeCantidad='1.5' sizeTiulo='1.5' />
            <div className="row mt-5 mb-5">
                <div className="col-12 col-md-1"></div>
                <div className="col-12 col-md-10">
                  {cuenta?<Chartjs Data={chartData} type='line'/>:<center><button className='btn btn-primary mb-2 gen-button' onClick={() => navigate(`/profile`)}> {t('mt4.create_account')} </button></center>}
                </div>
                <div className="col-12 col-md-1"></div>
                {/* <div className="col-12 col-md-5">
                    <h2 class="gen-fontwhite">{t('index.deposit')}</h2>
                    <hr />
                    <Deposito codigoCuenta={informacionUsuario.cuenta} />
                </div> */}
            </div>
            {/* <Operacion /> */}
        </React.Fragment>
    );
}

export default Inicio;