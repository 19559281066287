import React from 'react';
import { composeInitialProps, useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { Link } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import MetodosPago from "./MetodosPago";

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function Deposits_prt1({funcionPaso}) {
	const { t } = useTranslation();

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [paySelected, setPaySelected] = React.useState(0);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function setPayForm(id) {
        setPaySelected(id);
        funcionPaso(id);
    }

    return (
        <div className='row text-center'> 
            {(paySelected == 1)?(<MetodosPago className="col-5" title="Pagadito" id="1" selection={setPayForm} selected={true} />):(<MetodosPago className="col-5" title="Pagadito" id="1" selection={setPayForm}  selected={false}/>)}
            {(paySelected == 2)?(<MetodosPago className="col-5" title="PAYRETAILERS" id="2" selection={setPayForm} selected={true} />):(<MetodosPago className="col-5" title="PAYRETAILERS" id="2" selection={setPayForm}  selected={false}/>)}
            {(paySelected == 3)?(<MetodosPago className="col-5" title="Tarjeta de Credito" id="3" selection={setPayForm} selected={true} />):(<MetodosPago className="col-5" title="Tarjeta de Credito" id="3" selection={setPayForm}  selected={false}/>)}
            {(paySelected > 0) ? (<Link className='btn btn-success' to="prt2"> {t('fondos.deposits.Next_buttom')} </Link>):(<div className='btn btn-light btn-not-allowed' > {t('fondos.deposits.Next_buttom')} </div>)}
        </div>
    );
}

export default Deposits_prt1;