import React from 'react';
import { useTranslation } from "react-i18next";
import { newDeposit } from '../../../config/helper';
import { useDispatch } from 'react-redux';
import { showAlertAccion, closeAlertAccion } from '../../../redux/alert_webtrade';

function Deposits_prt3({funcionPaso, montoDepositar, pasoDeposito, codigoCuenta}) {
	const { t } = useTranslation();
    const dispatch = useDispatch();

    const aceptarTransaccion = () => {
        funcionPaso(3);
    }

    const openModal = (message,typeAlert) =>{
        dispatch(showAlertAccion({message,typeAlert}));
    }

    const closeModal = () =>{
        setTimeout(() => {
            dispatch(closeAlertAccion());
        },4000);
    }
    
    const enviarTransaccion = async() => {
        try {
            const account = codigoCuenta;
            const method = 'payretail';
            const amount = montoDepositar;

            openModal(t('fondos.deposits.loading_data'),'light');
            const {message, body } = await newDeposit({method,account,amount})
            
            if(message === 'Successful'){
                location.href = body.link;
            }else{
                openModal(t('fondos.deposits.error_data'),'danger');
                closeModal();
            }

        } catch (error) {
            openModal(t('fondos.deposits.error_data'),'danger');
            closeModal();
        }
    }

    return (
        <React.Fragment>
            <h1 className='gen-fontwhite'> {t('fondos.deposits.resume')} </h1>
            <div className="row text-white">
                <div className="col-4"> {t('fondos.deposits.Payment_method')} </div><div className="col-8"> {t('fondos.deposits.pay-retailers')} </div>
                <div className="col-4"> {t('fondos.deposits.account')} </div><div className="col-8"> {codigoCuenta} </div>
                <div className="col-4"> {t('fondos.deposits.Coin')} </div><div className="col-8"> {t('fondos.deposits.USD')} </div>
                <hr />
                <div className="col-4"> {t('fondos.deposits.total')} </div><div className="col-8">{montoDepositar}  {t('fondos.deposits.USD')} </div>
            </div>
            {/* <Link className='btn btn-danger' to="../prt2"> {t('fondos.deposits.Back_buttom')} </Link> */}
            {(pasoDeposito >= 3 ? (<button className='btn btn-success' onClick={enviarTransaccion}> {t('fondos.deposits.Send')} </button>):(<button className='btn btn-info' onClick={aceptarTransaccion}> {t('fondos.deposits.Accept')} </button>))}
            
        </React.Fragment>
    );
}

export default Deposits_prt3;