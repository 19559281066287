import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import Cuerpo from './Cuerpo';
import Navbar from './Navbar';
import Header from './Header';
import Login from './Login';
import Register from './Register';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { validarTokenAccion } from '../redux/informacion_principal';
import AlertWebtrade from './AlertWebtrade';
import Menu from './Menu';

function IndexApp() {
	const data = useSelector(store => store);
    const cookies = new Cookies();
    const dispatch = useDispatch();

    useEffect(() => {
        let token = cookies.get('userTokenS', { path: '/'});
        console.log('valida el token:', token);
        if(token){
            dispatch(validarTokenAccion(token))
        }
    }, []);

    let token = data.informacionUsuario.token;
    let cuentas = data.informacionUsuario.role;

	return (
		<React.Fragment><BrowserRouter>
            {(token && cuentas)?
                (
                    <div><Header />
                    <div className="container-fluid">
                        <div className="row flex-grow-sm-1 flex-grow-0">
                            <Menu />
                            {/* <Navbar /> */}
                            <Cuerpo />
                        </div>
                    </div></div>
                ):(
                    <Routes>
                        <Route path="/*" element={<div className='login-component'><Login /></div>} />
                        <Route path="/register" element={<Register />}/>
                    </Routes>
                )
            }</BrowserRouter>
            <AlertWebtrade />
		</React.Fragment>
		);
}

export default IndexApp;
