import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import { useDispatch } from 'react-redux';
import { showAlertAccion, closeAlertAccion } from '../../../redux/alert_webtrade';


function Deposits_prt2({montoDepositar, name, codigoCuenta, funcionMonto , changeMontoImporte}) {
	const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openModal = (message,typeAlert) =>{
        dispatch(showAlertAccion({message,typeAlert}));
    }

    const closeModal = () =>{
        setTimeout(() => {
            dispatch(closeAlertAccion());
        },4000);
    }


    function changeMontoImporte(number) {
        var num = number;
        if (num == ''){
            num = 0;
            funcionMonto(num);
            funcionPaso(1);
        }else{
            num = parseInt(number);
            funcionMonto(num);
            funcionPaso(2);
    }
    }

    const nextOption = () => {
        console.log(name)
        openModal(t('fondos.deposits.loading_data'),'light');
        setTimeout(() => {
            openModal(t('La solicitud de depósito ha sido enviada. Uno de nuestros asesores lo validará en breve.'),'success');
            closeModal();

            navigate(`/`)
        },4000);
    }

    const validarMonto = () => {
        console.log('montoDepositar')
        if (montoDepositar > 0){
            return true
        }else{
            openModal(t('El monto del deposito debe ser mayor a 0.'),'danger');
            closeModal();
            return false
        }
    }

    return (
        <React.Fragment>
            <h2 className="gen-fontwhite"> {t('fondos.deposits.Title_Part_2')} </h2>

            <div className="row m-5">
                {/* <div className="col-md-6">
                    <label className="form-text gen-fontwhite" htmlFor="inputImporte"> {t('fondos.withdrawals.form.name')} </label><br/>
                </div> */}
                {/* <div className="col-md-6"> */}
                    {/* <label className="form-text gen-fontwhite" htmlFor="inputImporte"> {name} </label><br/> */}
                {/* </div> */}
                <div className="col-12 row">
                    {/* <div className="col-0 col-md-3"></div> */}
                    <div className="col-12 col-md-12">
                        <label className=" gen-fontwhite" htmlFor="inputImporte"> {t('fondos.deposits.account')} {codigoCuenta}  </label><br/>
                    </div>
                    {/* <div className="col-12 col-md-3">
                        <label className=" gen-fontwhite" htmlFor="inputImporte"> {codigoCuenta} </label><br/>
                    </div> */}
                    {/* <div className="col-0 col-md-3"></div> */}
                </div>
                <div className="col-md-12 row">
                    <div className="col-0 col-md-3"></div>
                    <div className="col-12 col-md-3">
                        <div className="col-0 col-md-3"></div>
                        <label className="gen-fontwhite" htmlFor="inputImporte"> {t('fondos.deposits.Amount')} </label><br/>
                        {/* <select className=' gen-input-text' name="" id="" onChange={(e) => changeMontoImporte(e.target.value)}>
                            <option value="0">0</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="500">500</option>
                            <option value="700">700</option>
                            <option value="1000">1000</option>
                        </select> */}
                        <input className=' gen-input-text' onChange={(e) => changeMontoImporte(e.target.value)} type="number" step="0.10"  placeholder="0.00" name="" id="" />
                    </div>
                    <div className=" col-12 col-md-3 text-white">
                        <label  className="form-text gen-fontwhite" htmlFor="inputImporte"> {t('fondos.deposits.Coin')} </label><br/>
                        {t('fondos.deposits.USD')} 
                    </div>
                    <div className="col-0 col-md-3"></div>
                </div>
                {/* <div className="col-4">
                    <button className='btn btn-info get-btn-monto' onClick={() => changeMontoImporte(t('fondos.deposits.First_Amount'))}> {t('fondos.deposits.First_Amount')}  {t('fondos.deposits.USD')} </button>
                </div>
                <div className="col-4">
                    <button className='btn btn-info get-btn-monto' onClick={() => changeMontoImporte(t('fondos.deposits.Second_Amount'))}> {t('fondos.deposits.Second_Amount')}   {t('fondos.deposits.USD')} </button>
                </div>
                <div className="col-4">
                    <button className='btn btn-info get-btn-monto' onClick={() => changeMontoImporte(t('fondos.deposits.third_Amount'))}> {t('fondos.deposits.third_Amount')}   {t('fondos.deposits.USD')} </button>
                </div> */}
            </div>
            {/* <Link className='btn btn-danger' to="../"> {t('fondos.deposits.Back_buttom')} </Link> */}
            {/* {(montoDepositar > 0) ? (<Link className='btn btn-success' to="../prt3"> {t('fondos.deposits.Next_buttom_card')} </Link>):(<div className='btn btn-light btn-not-allowed' > {t('fondos.deposits.Next_buttom_card')} </div>)} */}
            {montoDepositar > 0 ? (<button onClick={()=>nextOption('card')} className='btn gen-button text-white m-2'> {t('fondos.deposits.Next_buttom_card')} </button>):(<div className='btn gen-button text-white btn-not-allowed m-2' onClickCapture={() => validarMonto()} > {t('fondos.deposits.Next_buttom_card')} </div>)}
            {montoDepositar > 0 ? (<button onClick={()=>nextOption('money')} className='btn gen-button text-white m-2'> {t('fondos.deposits.Next_buttom_money')} </button>):(<div className='btn gen-button text-white btn-not-allowed m-2' onClickCapture={() => validarMonto()} > {t('fondos.deposits.Next_buttom_money')} </div>)}
            {/* {(montoDepositar > 0) ? (<Link className='btn btn-success m-3' to="../prt3"> {t('fondos.deposits.Next_buttom_money')} </Link>):(<div className='btn btn-light btn-not-allowed' > {t('fondos.deposits.Next_buttom_money')} </div>)} */}
        </React.Fragment>
    );
}

export default Deposits_prt2;