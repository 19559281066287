import React, { useEffect, useRef } from 'react';
import '../styles/navbar.css';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { AccountInfoAccion } from '../redux/investment_account';
import { obtenerUsuarioAccion, logoutAccion } from '../redux/informacion_principal'
import { showAlertAccion, closeAlertAccion } from '../redux/alert_webtrade';

function Header() {

	
	const { t } = useTranslation();
    //const serverUrl = 'ws://localhost:3001/websocket/statusaccount';
	const serverUrl = process.env.REACT_APP_SOCKETLOCAL+"/websocket/statusaccount";
	
	const header_cabinet = {
		width: "100%"
	}

	const store = useSelector(store => store)
	const cuentas = store.informacionUsuario.accounts.demo && store.informacionUsuario.accounts.live ? true:false; 
	const dispatch = useDispatch();
	
	const openAlert = (message,typeAlert) =>{
		dispatch(showAlertAccion({message,typeAlert}));
	}

	const closeAlert = () =>{
		dispatch(closeAlertAccion());
	}
	const connectionRef = useRef(null);
	
	const connectWebSocket = (account) => {
		connectionRef.current = new WebSocket(serverUrl);
	
		const socket = connectionRef.current;
	
		socket.onopen = () => {
		// Enviar el mensaje al servidor cuando te conectes
            const message = JSON.stringify({
              event: 'subscribe',
              account,
            });
            socket.send(message);
        };
	
		socket.onmessage = (event) => {
			const data = JSON.parse(event.data)
			dispatch(AccountInfoAccion(data));
        };
	
		socket.onclose = () => {
            console.log('Conexión cerrada');
        };
	};

    useEffect(() => {

		connectWebSocket(store.informacionUsuario.accounts[store.informacionUsuario.role]);

      }, []); 
	  
	  const changeAccount = async(type) => {

		  openAlert('Cambiando de Cuenta','light');

		  dispatch(obtenerUsuarioAccion(type))

		  connectionRef.current.close();
  
		  connectWebSocket(store.informacionUsuario.accounts[type]);

		  await new Promise(resolve => setTimeout(resolve, 3000));

		  closeAlert();

	  }

	  const logout = () => {
        // Cerrar la conexión WebSocket antes de hacer logout
        if (connectionRef.current) {
            connectionRef.current.close();
        }

        dispatch(logoutAccion());
    };

	

		return (
			<React.Fragment>
				<header className='App-header mb-4 gen-header'>
					<div className="row" style={header_cabinet}>
						<div  className="col-8 col-sm-8 col-lg-10 order-1 order-sm-1 text-start">
								<img className='logo-header' src="/img/logotipo.png" alt="" />
						</div>
						{cuentas?<div className="col-12 col-sm-3 col-lg-1 order-3 order-sm-2">
								<div>{store.informacionUsuario.role}</div>
								<div>{store.informacionUsuario.accounts[store.informacionUsuario.role]}</div>
						</div>:<div></div>}
						<div className="col-4 col-sm-1 col-lg-1 order-2 order-sm-3 no-imprimir">
							<ul style={{justifyContent: 'right'}} className="nav">
								<li>
									<div to="#" className="text-white  dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="true">
										<img src="/img/user.jpg" alt="user" width="32" height="32" className="rounded-circle" />
									</div>
									<ul>
										{cuentas?
											<li className={` ${ store.informacionUsuario.role == 'demo'? 'on':'off' }`} onClick={() => changeAccount('demo')}> {t('header.demo')} {store.informacionUsuario.accounts.demo} </li>
										:null}
										{/* { store..live === '0' ? (<li className={`text-white`} onClick={() => createLive()}> {t('header.notLive')} </li>):(<li className={`text-white ${ tipoCuenta == 'live'? 'on':'off' }`} onClick={() => dispatch(obtenerUsuarioAccion('live'))}> {t('header.live')} {store.informacionUsuario.cuentas.live} </li>)  } */}
										{cuentas?
											<li className={` ${ store.informacionUsuario.role == 'live'? 'on':'off' }`} onClick={() => changeAccount('live')}> {t('header.live')} {store.informacionUsuario.accounts.live} </li>
										:null}
										<li  onClick={() => logout()} className='logout-style'>
											<div className='logout-style'>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-bar-down gen-fontwhite" viewBox="0 0 16 16">
													<path fillRule="evenodd" d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zM1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
												</svg>
												<span className="gen-fontwhite">{t('menu.logout')}</span>
											</div> 
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</header>
            </React.Fragment>
		);
}

export default Header;