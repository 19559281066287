import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import { getHistoryData } from '../config/helper'
import Loading from '../components/Loading';

function Reports() {

  let granTotal = 0;
  const fechaActual = new Date().toLocaleDateString()
	const store = useSelector(store => store)
  // const movements = store.investmentInfoAccount.movements
  const dataUser = store.investmentInfoAccount.dataUser.data
  // const movements = await dgetHistoryData({account,pageNumber,pageSize})
  const [movements, setMovements] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {

    const capData = async() =>{
      await updateData();
    }

    capData()

  }, []);

  useEffect(() => {

    const capData = async() =>{
      await updateData();
    }

    capData()

  }, [store.informacionUsuario.role]);

  const updateData = async () =>{
    console.log('comienza a recaudar la info')
    const pageNumber = 1
    const pageSize = 0
    const account = store.informacionUsuario.accounts[store.informacionUsuario.role]
    setLoader(false)
    const data = await getHistoryData({account,pageNumber,pageSize,sort:1})
    setMovements(data.body)
    setLoader(true)
  }

  const showTypeEsp = (type) =>{
      let traslate = ''
      if(type === 'deposit'){
          traslate = 'Depósito'
      }else if(type === 'credit'){
          traslate = 'Crédito'
      }else if(type === 'withdraw'){
        traslate = 'Retiro'
      }else if(type === 'trade'){
          traslate = 'Orden'
      }

      return traslate
  }

    // Create styles
  const styles = {
    page: {
        flexDirection: "column",
        borderRadius:"0px"
    },
    header: {
        fontSize: 15,
        fontWeight: 700,  
        backgroundColor:"grey",
        padding:10,
        marginBottom:25,
        flexDirection: "row",
        textAlign:"center",
        borderRadius:"0",
        marginLeft:"0",
        marginRight:"0"
    },
    text:{
      width: "35%",
    },
    image: {
        width:"40%" 
    },
    title: {
        textAlign:"center",
        fontWeight: 800,  
        fontSize:25
    },
    table: {
        flexDirection: "row",
        flexWrap: "wrap",
        margin:"5px",
        borderRadius:"0px"
    },
    tableHeader: {
        title:{
          flexDirection: "row",
          textAlign: "center",
          backgroundColor: "grey",
          color:"white",
          fontWeight: 800,  
          paddingBottom:"5px",
          paddingTop:"5px",
          borderRadius:"0px",
          marginBottom:"10px",
          marginLeft:"-5px",
          marginRight:"-5px"
        },
        column:{
            width: "10%",
            height:"25px",
        }
    },
    colum: {
      paddingTop:"5px",
      fontSize: 12,
      width: "10%",
      height:"40px",
      borderBottomWidth:1,
      borderBottomStyle:'solid',
      borderBottomColor:'black',
      textAlign:"center",
      borderRadius:"0px"
    },
    tableBody: {
      flexDirection: "row",
      alignItems: "center"
    }
  }                                                             ;

  const limpiarFecha = (date) => {
    return date.split('.')[0].replace('-', '/').replace('-', '/').replace('T', ' ').replace('Z', '')
  }

  return (
    loader ? <div className='imprimir' style={{textAlign:'right',borderLine:'none', backgroundColor:'white'}}>
      <button className='btn btn-dark m-3 no-imprimir' onClick={()=>{window.print();}} type="button">Imprimir</button>
      <div style={styles.page}>
        <div style={styles.header} className='row'>
          {/* <div  className='col-4'> Account: {data.cuenta} </div > */}
          <div  className='col-6'> Name: {dataUser.firstName} {dataUser.lastName} </div >
          <div  className='col-6'> Date: { fechaActual } </div >
        </div>
        <div style={styles.title}>
          <div>Historial de Movimientos</div>
        </div>
        <div style={styles.table}>
            <div  style={styles.tableHeader.title} className='row'>
              <div style={styles.tableHeader.column} className='col-1'>Ticket</div >
              <div style={styles.tableHeader.column} className='col-2'>Open Time</div >
              <div style={styles.tableHeader.column} className='col-1'>Type</div >
              <div style={styles.tableHeader.column} className='col-1'>Size</div >
              <div style={styles.tableHeader.column} className='col-1'>Item</div >
              <div style={styles.tableHeader.column} className='col-1'>Price</div >
              <div style={styles.tableHeader.column} className='col-1'>S / L</div >
              <div style={styles.tableHeader.column} className='col-1'>T / P</div >
              <div style={styles.tableHeader.column} className='col-1'>Price</div >
              <div style={styles.tableHeader.column} className='col-2'>Profit</div >
            </div>
            { movements.map((row, index) => {
                granTotal += row.amount;
                return (<div style={styles.tableBody} className='row' key={index}>
                  <div style={styles.colum} className='col-1'>{ row.code }</div>
                  <div style={styles.colum} className='col-2'>{ limpiarFecha(row.createdAt) }</div>
                  {row.type === 'trade' ? <div style={styles.colum} className='col-2'>{ showTypeEsp(row.type) } ({ row.dataOperation.operation })</div>:<div style={styles.colum} >{ showTypeEsp(row.type) }</div>}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.volumen }</div>:<div style={{...styles.colum, width:"60%"}}> { row.comment } </div>}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.symbol }</div>:null}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.price }</div>:null}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.stop_loss }</div>:null}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.take_profit }</div>:null}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.actualPrice }</div>:null}
                  <div style={styles.colum} >{ row.amount.toFixed(6) }</div>
                </div >)               
              }) }
              <div style={{...styles.colum, textAlign:'right',width: "100%"}} >Gran Total: { granTotal ? granTotal.toFixed(6):0 }</div>
        </div>
      </div>
    </div>:<div><Loading show={true} /></div>
  );

}

export default Reports;