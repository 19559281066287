import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import PieChart from './components/PieChart';
import LineChart from './components/LineChart';

function Chartjs({Data, type }) {

  return (
		<div >
			{/* <PieChart chartData={chartData} /> */}
      {/* <LineChart chartData={chartData} /> */}
      <LineChart chartData={Data} />
		</div>
	);
}

export default Chartjs;