import Cookies from 'universal-cookie';
const cookies = new Cookies();
const company = process.env.REACT_APP_COMPANY_NAME;
// import generateStore from '../redux/store'

const searchToken = () => {

    let token = cookies.get('userTokenS', { path: '/'});
    return token

}

const searchType = () => {

    let token = cookies.get('typeAccout', { path: '/'});
    return token

}

export async function GET(link){
    try {
        // const token = cookies.get('userTokenS', { path: '/'});

        const token = searchToken()

        const response = await  fetch(link, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'company': company,
                'apikey':token,
                'type-account':searchType()
            }})
            
        return response.json();

    } catch(error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}

export async function POST(link, body){
    try {

        // const token = cookies.get('userTokenS', { path: '/'});

        const token = searchToken()

        const response = await  fetch(link, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'company': company,
                'apikey':token,
                'type-account':searchType()
            },
            body: JSON.stringify(body)    
        })
            
        return response.json();

    } catch(error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}