import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import TemplatePdf from '../../../components/TemplatePdf';
import { Link } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import { getHistoryData } from '../../../config/helper'
import { historyInfoAccion } from '../../../redux/investment_account';
import Loading from '../../../components/Loading';
 
function MovementsHistory({changeSendInfo, selectedItem}) {

	const { t } = useTranslation();
    const [pageNumber, setPageNumber] = useState(1);
    const [sort, setSort] = useState(-1);
    const [loadingShow,setLoadingShow] = React.useState(false);
	const store = useSelector(store => store)
	const dispatch = useDispatch();

    useEffect(() => {

		updateData();

    }, []);

    useEffect(() => {

        updateData();

    }, [store.informacionUsuario.role]);

    const showTypeEsp = (type) =>{
        let traslate = ''
        if(type === 'deposit'){
            traslate = 'Depósito'
        }else if(type === 'withdraw'){
            traslate = 'Retiro'
        }else if(type === 'trade'){
            traslate = 'Orden'
        }else if(type === 'credit'){
            traslate = 'Crédito'
        }

        return traslate
    }

    const updateData = async(pageNumberVar = 1) => {

        setLoadingShow(true);
        const account = store.informacionUsuario.accounts[store.informacionUsuario.role]
        const pageSize = 10

        const { body } = await getHistoryData({account,pageNumber:pageNumberVar,pageSize,sort})

		dispatch(historyInfoAccion(body));
        setLoadingShow(false);

    }

    const nextPage = async() =>{
        const newPageNumber = pageNumber + 1;
        setPageNumber(newPageNumber);
        updateData(newPageNumber)
    }

    const afterPage = async() =>{
        if(pageNumber>1){
            const newPageNumber = pageNumber - 1;
            setPageNumber(newPageNumber);
            updateData(newPageNumber)
        }
    }

    const changeSort = async() =>{
        const newSort = sort * -1
        setSort(newSort)
    }

    useEffect(() => {

		updateData(pageNumber);

    }, [sort]);


    const svgDown = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
  </svg>

    const svgUp = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
  </svg>

    // useEffect(() => {
    //     obtenerDatos()
    // }, []);

    // const obtenerDatos = () => {
    //     const arrayData = store.investmentInfoAccount.movements

    //     arrayData.forEach(function(elemento, indice, array){

    //         arrayData.push({
    //             order:elemento.Order,
    //             time:elemento.Time,
    //             type:elemento.Type,
    //             size:elemento.Size,
    //             symbol:elemento.Symbol,
    //             price:elemento.Price,
    //             stopLoss:elemento.stopLoss,
    //             takeProfit:elemento.takeProfit,
    //             actualPrice:actualPrice,
    //             swap:elemento.Swap,
    //             profit:profit.toFixed(2)
    //             })

    //     });
            


    //     setData(arrayData);
    // }

		return (
            <div className="container mt-3">
                {/* <TemplatePdf movements={store.investmentInfoAccount.movements} data={store.informacionUsuario} dataUser={store.investmentInfoAccount.dataUser.data} />    */}
                <div style={{textAlign:'right',borderLine:'none',background:'#fff'}}>
                    <Link to="/report"  style={{color:'black',background:'#fff'}} className={`btn btn-outline-dark`}>  Descargar Historial </Link>
                    <button style={{color:'black',background:'#fff'}} className={`btn btn-outline-dark`} onClick={()=>updateData(pageNumber)} >Actualizar</button>
                </div>
                {store.investmentInfoAccount.movements && !loadingShow ? 
                <div>
                    <table className="table table-striped gen-fontwhite">
                        <thead>
                            <tr>
                                <th> {t('mt4.order')} </th>
                                <th> {t('mt4.type')} </th>
                                <th> {t('mt4.symbol')} </th>
                                <th style={{cursor:'pointer'}} onClick={() => changeSort()}> {t('mt4.time')} {sort === 1 ? svgDown:svgUp}</th>
                                <th> {t('mt4.expire_time')} </th>
                                <th> {t('mt4.price')} </th>
                            </tr>
                        </thead>
                        <tbody>
                            {store.investmentInfoAccount.movements.map(item => (
                                <tr className='trade-unselected gen-fontwhite' key={item.code}>
                                    <td>{ item.code }</td>
                                    <td>{ showTypeEsp(item.type) }</td>
                                    <td>{ item.dataOperation ? item.dataOperation.symbol:null }</td>
                                    <td>{ item.createdAt }</td>
                                    <td>{ item.expire_date }</td>
                                    <td>{ item.amount.toFixed(6) }</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="controls-table">
                        <button style={{color:'black',background:'#fff'}} className={`btn btn-outline-dark`} onClick={() => afterPage()} >{'<'}</button>
                        <div style={{color:'black',background:'#fff'}} className={`btn btn-outline-dark`}> Pag. {pageNumber} </div>
                        <button style={{color:'black',background:'#fff'}} className={`btn btn-outline-dark`} onClick={() => nextPage()} >{'>'}</button>
                    </div>
                </div>:<div className='white-text'><Loading show={loadingShow} /></div> }
            </div>
		);
}

export default MovementsHistory;