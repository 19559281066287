import { investInformation } from '../config/helper';

// constantes
const dataInicial = {
    balance:0.00,
    credit:0.00,
    equity:0.00,
    margin:0.00,
    freeMargin:0.00,
    marginLevel:0.00,
    totalDepositado:0.00,
    dataUser:[],
    movements:[],
    trades:[]
}

//types
const GET_INFORMACION = 'GET_INFORMACION'
const GET_HISTORY_INFORMACION = 'GET_HISTORY_INFORMACION'
const GET_CARGAR_INFO_ACCOUNT = 'GET_CARGAR_INFO_ACCOUNT'
const GET_ACTUALIZAR_INFORMACION = 'GET_ACTUALIZAR_INFORMACION'
const GET_AGREGAR_CREDITO = 'GET_AGREGAR_CREDITO'

//
export default function investmentAccount(state = dataInicial, action){
    switch(action.type){
        case GET_INFORMACION:
            return {...state,
                type:GET_INFORMACION,
                balance:action.balance,
                credit:action.credit,
                equity:action.equity,
                margin:action.margin,
                freeMargin:action.freeMargin,
                marginLevel:action.marginLevel,
                totalDepositado:action.totalDepositado,
                dataUser:action.dataUser,
                // movements:action.movements,
                trades:action.trades}
        case GET_HISTORY_INFORMACION:
            return {...state,
                type:GET_HISTORY_INFORMACION,
                movements:action.movements}
        case GET_CARGAR_INFO_ACCOUNT:
            return {...state,
                balance:action.balance,
                credit:action.credit,
                equity:action.equity,
                margin:action.margin,
                freeMargin:action.freeMargin,
                marginLevel:action.marginLevel,
                trades:action.trades}
        case GET_ACTUALIZAR_INFORMACION:
            return {...state,
                equity:action.equity,
                margin:action.margin,
                freeMargin:action.freeMargin,
                marginLevel:action.marginLevel,
                trades:action.trades}
        default:
            return state
    }
}

// acciones

export const AccountInfoAccion = (data) => async (dispatch, getState) => {
    try {
        // const { dataUser, dataAccount, historyMovement, historyInvest } = data;
        const { dataUser, dataAccount, historyInvest } = data;
        const { balance, credit, equity, margin, freeMargin, totalDeposit, marginLevel } = dataAccount;
        
        dispatch({
            type:GET_INFORMACION,
            balance,
            credit,
            equity,
            margin,
            freeMargin,
            marginLevel,
            totalDepositado:totalDeposit,
            dataUser,
            // movements:historyMovement,
            trades:historyInvest
            })
        
    } catch (error) {
        console.log('Error en aplicación:',error);
    }
}

export const historyInfoAccion = (movements) => async (dispatch, getState) => {
    try {
        
        dispatch({
            type:GET_HISTORY_INFORMACION,
            movements,
            })
        
    } catch (error) {
        console.log('Error en aplicación:',error);
    }
}

// export const agregarCreditoAccion = (credit) => (dispatch, getState) => {
//     try {

//         //Obtener datos de crédito actuales.
//         newCredit = credit + getState.credit

//         //Enviar dato.
//         dispatch({
//             type:GET_AGREGAR_CREDITO,
//             credit:newCredit,
//             })

//     } catch (error){
//         console.log(error)
//     }
// }