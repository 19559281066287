import React from 'react';
import { Document, Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import imgLogo from "./logotipo.png";
import { PDFDownloadLink } from '@react-pdf/renderer';

function TemplatePdf({movements, data, dataUser}) {

  let granTotal = 0;
  const fechaActual = new Date().toLocaleDateString()

    // Create styles
  const styles = StyleSheet.create({
    page: {
      fontSize: 12,
      flexDirection: "column",
    },
    header: {
      backgroundColor:"grey",
      padding:10,
      marginBottom:25,
      flexDirection: "row",
      textAlign:"center"
    },
    text:{
      width: "35%",
    },
    image: {
      width:"40%" 
    },
    title: {
      textAlign:"center",
      fontSize:20
    },
    table: {
      borderWidth:1,
      borderStyle:'solid',
      borderColor:'black',
      flexDirection: "row",
      flexWrap: "wrap",
      margin:"5px",
    },
    tableHeader: {
      title:{
        flexDirection: "row",
        textAlign: "center",
        backgroundColor: "grey",
        color:"white",
        fontWeight: 800,
        paddingBottom:"5px",
        paddingTop:"5px",
      },
      column:{
        width: "10%",
        height:"25px",
      }
    },
    colum: {
      paddingTop:"5px",
      fontSize: 8,
      width: "10%",
      height:"25px",
      borderBottomWidth:1,
      borderBottomStyle:'solid',
      borderBottomColor:'black',
      textAlign:"center",
    },
    tableBody: {
      flexDirection: "row",
      alignItems: "center"
    }
  });

  const limpiarFecha = (date) => {
    return date.split('.')[0].replace('-', '/').replace('-', '/').replace('T', ' ').replace('Z', '')
  }

  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          {/* <Image style={styles.image} src={imgLogo}/> */}
          <Text style={styles.text}> Account: {data.cuenta} </Text >
          <Text style={styles.text}> Name: {dataUser.firstName} {dataUser.lastName} </Text >
          <Text style={styles.text}> Date: { fechaActual } </Text >
        </View>
        <View style={styles.title}>
          <Text>Historial de Movimientos</Text>
        </View>
        <View style={styles.table}>
            <View  style={styles.tableHeader.title}>
              <Text style={styles.tableHeader.column}>Ticket</Text >
              <Text style={styles.tableHeader.column}>Open Time</Text >
              <Text style={styles.tableHeader.column}>Type</Text >
              <Text style={styles.tableHeader.column}>Size</Text >
              <Text style={styles.tableHeader.column}>Item</Text >
              <Text style={styles.tableHeader.column}>Price</Text >
              <Text style={styles.tableHeader.column}>S / L</Text >
              <Text style={styles.tableHeader.column}>T / P</Text >
              <Text style={styles.tableHeader.column}>Price</Text >
              <Text style={styles.tableHeader.column}>Profit</Text >
            </View>
              { movements.map((row, index) => {
                granTotal += row.amount;
                return (<View style={styles.tableBody} key={index}>
                  <Text style={styles.colum} >{ row.code }</Text>
                  <Text style={styles.colum} >{ limpiarFecha(row.createdAt) }</Text>
                  {row.type === 'trade' ? <Text style={styles.colum}>{ row.type } ({ row.dataOperation.operation })</Text>:<Text style={styles.colum} >{ row.type }</Text>}
                  {row.type === 'trade' ? <Text style={styles.colum}>{ row.dataOperation.volumen }</Text>:<Text style={{...styles.colum, width:"60%"}}> { row.comment } </Text>}
                  {row.type === 'trade' ? <Text style={styles.colum}>{ row.dataOperation.symbol }</Text>:null}
                  {row.type === 'trade' ? <Text style={styles.colum}>{ row.dataOperation.price }</Text>:null}
                  {row.type === 'trade' ? <Text style={styles.colum}>{ row.dataOperation.stop_loss }</Text>:null}
                  {row.type === 'trade' ? <Text style={styles.colum}>{ row.dataOperation.take_profit }</Text>:null}
                  {row.type === 'trade' ? <Text style={styles.colum}>{ row.dataOperation.actualPrice }</Text>:null}
                  <Text style={styles.colum} >{ row.amount.toFixed(6) }</Text>
                </View> )               
              }) }
              <Text style={{...styles.colum, textAlign:'right',width: "100%"}} >Gran Total: { granTotal ? granTotal.toFixed(6):0 }</Text>
        </View>
      </Page>
    </Document>
  )

  return (
    <div style={{textAlign:'right',borderLine:'none'}}>
      <PDFDownloadLink document={MyDocument()} fileName="FORM"> <button style={{color:'black'}} className='btn btn-outline-dark'> Descargar Documento </button> </PDFDownloadLink>
    </div>
  );

}

export default TemplatePdf;