import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'

import informacionPrincipal from './informacion_principal'
import investmentAccount from './investment_account'
import alertsWebtrade from './alert_webtrade'

const rootReducer = combineReducers({
    informacionUsuario: informacionPrincipal,
    investmentInfoAccount: investmentAccount,
    alertRedux: alertsWebtrade,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
    const store = createStore( rootReducer, composeEnhancers( applyMiddleware(thunk) ) )
    return store;
}




// import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import thunk from 'redux-thunk'

// import informacionPrincipal from './informacion_principal'
// import investmentAccount from './investment_account'
// import alertsWebtrade from './alert_webtrade'

// const rootReducer = combineReducers({
//     informacionUsuario: informacionPrincipal,
//     investmentInfoAccount: investmentAccount,
//     alertRedux: alertsWebtrade,
// })

// const persistConfig = {
//     key: 'root',
//     storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default function generateStore(){
//     const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
//     const persistor = persistStore(store);

//     return { store, persistor };
// }