import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { showAlertAccion, closeAlertAccion } from '../../../redux/alert_webtrade';
import { newWithdrawals } from '../../../config/helper';

function WithdrawalsBanco() {
	const { t } = useTranslation();

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [mail, setMail] = useState('');
    const [address, setAddress] = useState('');
    const [bancaAccount, setBancaAccount] = useState('');
    const [bancaName, setBancaName] = useState('');
    const [bancaAddress, setBancaAddress] = useState('');
    const [bancaBic, setBancaBic] = useState('');
    const [bancaIban, setBancaIban] = useState('');
    const [amount, setAmount] = useState('');
	
    const store = useSelector(store => store)
    const userData = store.informacionUsuario
	const dispatch = useDispatch();

    const openAlert = (message,typeAlert) =>{
        dispatch(showAlertAccion({message,typeAlert}));
    }

    const closeAlert = () =>{
        setTimeout(() => {
            dispatch(closeAlertAccion());
        },4000);
    }
    
    const getData = async () => {

        try {
            const account = userData.accounts.live;
            
            // const data={account,name,lastName,phone,mail,address,method:'banca',datos:{bancaAccount,bancaName,bancaAddress,bancaBic,bancaIban,amount}}
            
            // openAlert(t('fondos.withdrawals.loading_data'),'light');
            // const req = await newWithdrawals(data);

            // if( req.message === 'Successful' ){
            if(account && name && lastName && phone && mail && address && bancaAccount && bancaName && bancaAddress && bancaBic && bancaIban && amount){
                openAlert(t('fondos.withdrawals.save_data'),'success');
                    setName('');
                    setLastName('');
                    setPhone('');
                    setMail('');
                    setAddress('');
                    setBancaAccount('');
                    setBancaName('');
                    setBancaAddress('');
                    setBancaBic('');
                    setBancaIban('');
                    setAmount('');
                }else{
                    openAlert('¡Aviso! Todos los campos son obligatorios','danger');
                }
            // }else{
            //     openAlert(t('fondos.withdrawals.error_data'),'danger');
            // }

            } catch (error) {
            console.log(error)
            openAlert(t('fondos.withdrawals.error_data'),'danger');
        }
            closeAlert();
        }

    return (
        <React.Fragment>
            <div className="row">
               <form>
                    <div className="row text-start">
                        <h4 className="mb-3 mt-3"> {t('fondos.withdrawals.form.fist_title')} </h4>
                        <div className="mb-3 col-md-6 col-sm-12">
                            <label htmlFor="formName" className="form-label"> {t('fondos.withdrawals.form.name')} </label>
                            <input type="text" className="form-control gen-input-text" id="formName" aria-describedby="nameHelp" value={name} onChange={e => setName(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-6 col-sm-12">
                            <label htmlFor="formLastName" className="form-label"> {t('fondos.withdrawals.form.lastname')} </label>
                            <input type="text" className="form-control gen-input-text" id="formLastName" aria-describedby="lastNameHelp" value={lastName} onChange={e => setLastName(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-6 col-sm-12">
                            <label htmlFor="formPhone" className="form-label"> {t('fondos.withdrawals.form.phone')} </label>
                            <input type="text" className="form-control gen-input-text" id="formPhone" aria-describedby="phoneHelp" value={phone} onChange={e => setPhone(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-6 col-sm-12">
                            <label htmlFor="formPhone" className="form-label"> {t('fondos.withdrawals.form.email')} </label>
                            <input type="text" className="form-control gen-input-text" id="formPhone" aria-describedby="phoneHelp" value={mail} onChange={e => setMail(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-12 col-sm-12">
                            <label htmlFor="formIdentification" className="form-label"> {t('fondos.withdrawals.form.addres')} </label>
                            <input type="text" className="form-control gen-input-text" id="formIdentification" aria-describedby="identificationHelp" value={address} onChange={e => setAddress(e.target.value)} />
                        </div>
                    </div>
                    <div className="row text-start">
                        <h4 className="mb-3 mt-3"> {t('fondos.withdrawals.form_banco.second_title')} </h4>
                        <div className="mb-3 col-md-12 col-sm-12">
                            <label htmlFor="formName" className="form-label"> {t('fondos.withdrawals.form_banco.Bank_account_number')} </label>
                            <input type="text" className="form-control gen-input-text" id="formName" aria-describedby="nameHelp" value={bancaAccount} onChange={e => setBancaAccount(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-12 col-sm-12">
                            <label htmlFor="formName" className="form-label"> {t('fondos.withdrawals.form_banco.bank_name')} </label>
                            <input type="text" className="form-control gen-input-text" id="formName" aria-describedby="nameHelp" value={bancaName} onChange={e => setBancaName(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-12 col-sm-12">
                            <label htmlFor="formName" className="form-label"> {t('fondos.withdrawals.form_banco.bank_address')} </label>
                            <input type="text" className="form-control gen-input-text" id="formName" aria-describedby="nameHelp" value={bancaAddress} onChange={e => setBancaAddress(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-6 col-sm-12">
                            <label htmlFor="formName" className="form-label"> {t('fondos.withdrawals.form_banco.swift')} </label>
                            <input type="text" className="form-control gen-input-text" id="formName" aria-describedby="nameHelp" value={bancaBic} onChange={e => setBancaBic(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-6 col-sm-12">
                            <label htmlFor="formName" className="form-label"> {t('fondos.withdrawals.form_banco.iban')} </label>
                            <input type="text" className="form-control gen-input-text" id="formName" aria-describedby="nameHelp" value={bancaIban} onChange={e => setBancaIban(e.target.value)} />
                        </div>
                        <div className="mb-3 col-md-6 col-sm-12">
                            <label htmlFor="formLastName" className="form-label"> {t('fondos.withdrawals.form.monto')} </label>
                            <input type="text" className="form-control gen-input-text" id="formLastName" aria-describedby="lastNameHelp" value={amount} onChange={e => setAmount(e.target.value)} />
                        </div>
                    </div>
               </form>
            </div>
            <button onClick={()=>getData()} className="btn btn-primary"> {t('fondos.withdrawals.form.bottom')} </button>
        </React.Fragment>
    );
}

export default WithdrawalsBanco;