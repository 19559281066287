import {validarToken, login, logout, getInfo, getInformation} from '../config/helper'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// constantes
const dataInicial = {
    accounts:{},
    token: '',
    role:'',
}


//types
const GET_LOGIN = 'GET_LOGIN'
const GET_LOGOUT = 'GET_LOGOUT'
const GET_VALIDAR_TOKEN = 'GET_VALIDAR_TOKEN'
const GET_INFORMATION = 'GET_INFORMATION'
const GET_TIPO_USUARIO = 'GET_TIPO_USUARIO'
const ADD_ACCOUNTS = 'ADD_ACCOUNTS'

//
export default function informacionPrincipal(state = dataInicial, action){

    switch(action.type){
        case GET_LOGIN:
            return {...state, token:action.token, accounts:action.accounts, role:action.role}
        case GET_VALIDAR_TOKEN:
            return {...state, token:action.token, accounts:action.accounts, role:action.role}
        case ADD_ACCOUNTS:
            return {...state, accounts:action.accounts}
        case GET_TIPO_USUARIO:
            return {...state, role:action.role}
        case GET_LOGOUT:
            return {token:'', accounts:{}, role:'demo'}
        default:
            return state
        }
    }

// acciones

export const loginAccion = (cliente) => async (dispatch, getState) => {
    try {

        //Enviar datos y recibir la respuesta
        const logIn = await login({'usuario':cliente.usuario,'password':cliente.password});
        const token = logIn.body;
        
        if(logIn.message === 'Successful'){
            
            cookies.set("userTokenS", token, {path: "/"});
            const { message, body } = await getInformation();
            if(message === 'Successful'){
                dispatch({
                    type:GET_LOGIN,
                    accounts:body.accounts,
                    role:'demo',
                    token,
                });
            return token
            }
        }else{
            return('error')
        }

    } catch (e){
        console.log(e)
    }
}

export const validarTokenAccion = (token) => async (dispatch, getState) => {
    try {

        cookies.set("userTokenS", token, {path: "/"});
        const role = cookies.get("typeAccout", {path: "/"});
        const { message, body } = await getInformation();

        if (message === 'Successful') {
            dispatch({
                type:GET_LOGIN,
                accounts:body.accounts,
                role,
                token,
            });
        } else{
            cookies.remove('userTokenS', { path: '/'});
        }

    } catch (error){
        console.log(error)
    }
}

export const obtenerUsuarioAccion = (type) => async (dispatch, getState) => {
    try {
        cookies.set("typeAccout", type, {path: "/"});

        dispatch({
            type:GET_TIPO_USUARIO,
            role:type
        })
    } catch (error){
        console.log(error)
    }
}

export const logoutAccion = () => async (dispatch, getState) => {
    
    
    try {
        // const { message } = await logout();
        // if (message === 'Successful'){
        cookies.remove('userTokenS', { path: '/'});
        cookies.set("typeAccout", 'demo', {path: "/"});
        dispatch({
            type:GET_LOGOUT,
        });
        // }
        
    } catch (error){
        console.log(error)
    }
}

export const addAccounts = (accounts) => async (dispatch, getState) => {
    
    try {
        dispatch({
            type:ADD_ACCOUNTS,
            accounts:accounts,
        });
        
    } catch (error){
        console.log(error)
    }
}