import React, { useEffect } from 'react';
import { Routes, Route} from "react-router-dom"; /* Agregamos la libreria para las rutas */
import { useSelector} from 'react-redux'
import { closeAlertAccion } from '../redux/alert_webtrade';
import { useDispatch } from 'react-redux';

import Report from './Reports';
import Graficas from '../page/mt4/Graficas';
import Profile from '../page/profile'
import Inicio from '../page/Inicio'
import Withdrawals from '../page/fondos/components/Withdrawals'
import Deposits from '../page/fondos/components/Deposits'
import BloqueoDeCuenta from './BloqueoDeCuenta';

function Cuerpo() {

    const dispatch = useDispatch();
	const store = useSelector(store => store)

    useEffect(() => {
        dispatch(closeAlertAccion());
    }, []);

		return (
            <React.Fragment>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/socket.io/1.7.2/socket.io.js"></script>
                <main className="col overflow-auto h-100 body-print">
                    <div className="bg-light border rounded-3 p-3 backgroundgen" id="body">
                        <Routes>
                            <Route path="/*" element={<Inicio />} />
                            <Route path="/grafic/*" element={<Graficas />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/report" element={<Report />} />
                            {store.informacionUsuario.role === 'live'? (<Route path="/deposit/*" element={<Deposits codigoCuenta = {store.informacionUsuario.accounts.live} informacionUsuario = {store}  />} />):(<Route path="/deposit/*" element={<BloqueoDeCuenta />} />)}
                            {store.informacionUsuario.role === 'live'? (<Route path="/retiros/*" element={<Withdrawals codigoCuenta = {store.informacionUsuario.accounts.live} />} />):(<Route path="/retiros/*" element={<BloqueoDeCuenta />} />)}
                        </Routes>
                    </div>
                </main>
            </React.Fragment>
		);
}

export default Cuerpo;