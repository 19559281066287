import React from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import {Routes, Route} from "react-router-dom"; /* Agregamos la libreria para las rutas */
import DepositsOne from './Deposits_prt1';
import DepositsTwo from './Deposits_prt2';
import DepositsThree from './Deposits_prt3';
import CirculoPaso from './CirculoPaso';
import '../Fondos';

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function Deposits({codigoCuenta, informacionUsuario}) {
	const { t } = useTranslation();

    const [pasoDeposito, setPasoDeposito] = React.useState(0);
    const [tipoTransaccion, setTipoTransaccion] = React.useState(0);
    const [montoDeposito, setMontoDeposito] = React.useState(0);

    function avanzarPaso(paso){
        setPasoDeposito(paso);
    }
    function cantidadDepositar(monto){
        setMontoDeposito(monto);
    }

    return (
        <React.Fragment>
            {/* <div className='row mt-5 mb-5'>
                <div className='col-3'>
                    <CirculoPaso numero="1" paso={pasoDeposito} />
                </div>
                <div className='col-1'>{'>>'}</div>
                <div className='col-3'>
                    <CirculoPaso numero="2" paso={pasoDeposito} />
                </div>
                <div className='col-1'>{'>>'}</div>
                <div className='col-3'>
                    <CirculoPaso numero="3" paso={pasoDeposito} />
                </div>
            </div>    
            <hr /> */}
            <Routes>
                {/* <Route path="/" element={<DepositsOne funcionPaso={avanzarPaso}  />} /> */}
                <Route path="/" element={<DepositsTwo funcionMonto={cantidadDepositar} codigoCuenta={codigoCuenta} name={informacionUsuario} montoDepositar={montoDeposito} funcionPaso={(type) => setTipoTransaccion(type)}/>} />
                {/* <Route path="/prt2" element={<DepositsTwo funcionMonto={cantidadDepositar} montoDepositar={montoDeposito} funcionPaso={avanzarPaso}/>} /> */}
                <Route path="/prt3" element={<DepositsThree funcionPaso={avanzarPaso} montoDepositar={montoDeposito} pasoDeposito={pasoDeposito} codigoCuenta={codigoCuenta} />} />
            </Routes>
        </React.Fragment>
    );
}

export default Deposits;