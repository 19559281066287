import React from 'react';
import { Link, useNavigate } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import '../../../styles/itemNavbar.css'

function ItemNav({to, icon, name, type}) {
  return (
    <React.Fragment>    
        <li className="nav-link">
            {type === 'link'?
            (<Link to={to}>
              <div className="icon">
                <box-icon color='black' name={icon}></box-icon>
              </div>
              <span className='text nav-text'>{name}</span>
            </Link>):
            (<div onClick={()=>{redirigirASitioWeb(to)}} className="nav-link px-2 text-truncate gen-fontwhite div-menu">
                <box-icon color='#7d0612' name={icon}></box-icon>
                <span className='text nav-text'>{name}</span>
            </div>)
            }
        </li>
    </React.Fragment>
  );
}

export default ItemNav;