import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import '../styles/register.css'
import { showAlertAccion, closeAlertAccion } from '../redux/alert_webtrade';
import { saveNewLead } from '../config/helper'

function Register() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [conditions, setConditions] = useState(false);

	const { t } = useTranslation();
	const dispatch = useDispatch();

    const openAlert = (message,typeAlert) =>{
        dispatch(showAlertAccion({message,typeAlert}));
    }

    const closeAlert = () =>{
        dispatch(closeAlertAccion());
    }

    const hideMessage = (element) => {
        const alert = document.querySelector(`#${element}`);
        alert.classList.remove('show');    
    }

    const saveLead = async () => {
        try {
            if(!conditions){
                const alert = document.querySelector('#nota');
                alert.classList.add('show');
                return
            } 

            openAlert(t('login.login'),'light');
            const data = {
                firstName,
                lastName,
                email,
                phone,
                countryCode,
                campaing_id:".",
                source:"web",
                description:".",
                client:"11",
                url_source:"web"
            }

            const { message, body } = await saveNewLead(data)
            closeAlert();
            
            if(message === 'Successful'){
                setFirstName('');    
                setLastName('');    
                setEmail('');    
                setPhone('');    
                setCountryCode('');
                hideMessage('alert');
                hideMessage('nota');
                openAlert(t('register.success'),'success');
                setTimeout(()=>{closeAlert();},5000);
                
            }else{
                activarAlerta();
            }
            
        } catch (error) {
            console.log(`Error para registrar lead: ${error}`);
        }
    }

    const activarAlerta = () => {
        const alert = document.querySelector('#alert');
        alert.classList.add('show');
    }    

    return (
        <React.Fragment><center>
            <div className='login text-white'>
                <div className="row" >
                    <div className="col-xs-12">
                        <img className="logologin" src="/img/logotipo.png" alt="" />
                        <h2>{t('register.title')}</h2>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4 textlabellogin">
				        {/* <label htmlFor="user" className="form-label  mb-0 mt-2 labelloginuser"> {t('login.user')} </label> */}
                        <input 
                            type="text"
                            value={firstName} 
                            className="form-control inputuserlogin gen-input-text"
                            placeholder={t('register.name')}
                            onChange={e => setFirstName(e.target.value)} 
                            name="user" /* el name tiene que tener el mismo nombre de la variable */  
                            />
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
                <div className="row mt-3">
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
                        <input 
                            type="text" 
                            value={lastName} 
                            className="form-control inputuserlogin gen-input-text"
                            placeholder={t('register.last_name')}
                            onChange={e => setLastName(e.target.value)} 
                            name="user" /* el name tiene que tener el mismo nombre de la variable */  
                            />
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
                <div className="row mt-3">
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
                        <input 
                            type="text" 
                            value={email} 
                            className="form-control inputuserlogin gen-input-text"
                            placeholder={t('register.email')}
                            onChange={e => setEmail(e.target.value)} 
                            name="user" /* el name tiene que tener el mismo nombre de la variable */  
                            />
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
                <div className="row mt-3">
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
                        <input 
                            type="text" 
                            value={phone} 
                            className="form-control inputuserlogin gen-input-text"
                            placeholder={t('register.phone')}
                            onChange={e => setPhone(e.target.value)} 
                            name="user" /* el name tiene que tener el mismo nombre de la variable */  
                            />
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
                <div className="row mt-3">
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
                        <select name="country" className="form-control inputuserlogin gen-input-text" id="country" value={countryCode} onChange={(datos) => setCountryCode(datos.target.value)}>
                            <option key="." defaultValue=".">Selecciona tu pais</option>
                            <option key="PE" value="PE" > Peru </option>
                            <option key="COL" value="COL" > Colombia </option>
                            <option key="EC" value="EC" > Ecuador </option>
                            <option key="HN" value="HN" > Honduras </option>
                            <option key="SV" value="SV" > El Salvador </option>
                            <option key="NI" value="NI" > Nicaragua </option>
                            <option key="BO" value="BO" > Bolivia </option>
                            <option key="CR" value="CR" > Costa Rica </option>
                            <option key="PA" value="PA" > Panamá </option>
                            <option key="MX" value="MX" > Mexico </option>
                        </select>
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
                <div className="row mt-3">
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4 row">
                        <center><input type="checkbox" className="form-check-input register-checkbox col-2" name="note" id="note" onChange={(event)=>setConditions(event.target.checked)}/>
				        <label htmlFor="note" className="form-label mb-0 mt-2 ms-1 col-7 labelloginuser"> {t('register.note')} </label></center>
                        <div id="nota" className="alert alert-danger fade" role="alert">
                            <strong>{t('login.error')} </strong>{t('register.note_less')}
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
                <div className="row mt-3" >
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
                        <button className="btn gen-button text-white" onClick={()=>saveLead()}> {t('register.btn_crear')} </button>
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
            </div>
            <div id="alert" className="alert alert-danger fade col-xs-12 col-sm-8 col-md-6 col-lg-4" role="alert">
                <strong>{t('login.error')} </strong>{t('register.error')}
            </div>
            </center>
        </React.Fragment>
    );
}

export default Register;