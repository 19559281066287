import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import { changePasswordApi, getUserInformation, updateUserInformation, createUserInformation } from '../config/helper';
import InputsPassword from '../components/InputsPassword';
import { showAlertAccion, closeAlertAccion } from '../redux/alert_webtrade';
import { addAccounts } from '../redux/informacion_principal'
import { useNavigate } from "react-router-dom"; /* Agregamos la libreria para las rutas */

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background:"white"
    },
  };

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function profile() {

    const dispatch = useDispatch();
	const store = useSelector(store => store)
	const cuentas = store.informacionUsuario.accounts.demo && store.informacionUsuario.accounts.live ? true:false; 
    const navigate = useNavigate();


    const openAlert = (message,typeAlert) =>{
        dispatch(showAlertAccion({message,typeAlert}));
    }

    const closeAlert = () =>{
        dispatch(closeAlertAccion());
    }

    const { t } = useTranslation();
    const data = useSelector(store => store)
    const informacionUsuario = data.informacionUsuario
    const investmentInfoAccount = data.investmentInfoAccount

    const [userName, setUserName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userMobile, setUserMobile] = useState('');
    const [userIdentityCard, setUserIdentityCard] = useState('');
    const [userDateBirthday, setUserDateBirthday] = useState('');
    const [userAddressStreet, setUserAddressStreet] = useState('');
    const [userAddressCity, setUserAddressCity] = useState('');
    const [userAddressState, setUserAddressState] = useState('');
    const [userAddressPostalcode, setUserAddressPostalcode] = useState('');
    const [userAddressCountry, setUserAddressCountry] = useState('');
    const [userEducation, setUserEducation] = useState('');
    const [userExperience, setUserExperience] = useState('');
    const [userFinancialInstruments, setUserFinancialInstruments] = useState('');
    const [userRiskTolerance, setUserRiskTolerance] = useState('');
    const [userTradingFrequency, setUserTradingFrequency] = useState('');
    
    // const [countries, setCountries] = useState([]);
    // const [education, setEducation] = useState([]);
    // const [experience, setExperience] = useState([]);
    // const [financialInstruments, setFinancialInstruments] = useState([]);
    // const [riskTolerance, setRiskTolerance] = useState([]);
    // const [tradingFrequency, setTradingFrequency] = useState([]);
    
    const [modalIsOpen, setIsOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [rnewPassword, setRnewPassword] = useState('');
    
    const recopilarInformacion = async () => {
        const { message, body } = await getUserInformation();
        
        if(message === 'Successful'){
            const { personalData, addressData } = body;

            setUserName(personalData.name);
            setUserLastName(personalData.lastName);
            setUserMobile(personalData.phone);
            setUserIdentityCard(personalData.document);
            setUserDateBirthday(personalData.birthdate);
            setUserAddressStreet(addressData.street);
            setUserAddressCity(addressData.city);
            setUserAddressState(addressData.state);
            setUserAddressPostalcode(addressData.postalCode);
            setUserAddressCountry(addressData.country);
        }
    }
    
    useEffect(() => {
        recopilarInformacion(data);
    }, []);

    const updateUser = async () => {

        const data = {
            name: userName,
            lastName: userLastName,
            phone: userMobile,
            document: userIdentityCard,
            birthdate: userDateBirthday,
            street: userAddressStreet,
            city: userAddressCity,
            state: userAddressState,
            postalCode: userAddressPostalcode,
            country: userAddressCountry,
            education: userEducation,
            experience: userExperience,
            financialInstruments: userFinancialInstruments,
            riskTolerance: userRiskTolerance,
            tradingFrequency: userTradingFrequency,
        }

        openAlert('Actualizando Información','light');
        const { message } = await updateUserInformation(data);
        closeAlert();
        
        if( message === 'Successful' ){
            openAlert(t('profile.form.save_success'),'success');
        }else{
            openAlert('Error al actualizar Información','danger');
        }
        
        setTimeout( function(){
            closeAlert();
        }, 2000);
    
    }

    const validateData = () => {
        // Validar datos importantes.
        let message = 'Complete los datos faltantes:'
        let result = true;

        if(!userName){message += ', Nombres'; result = false}
        if(!userLastName){message += ', Apellidos'; result = false}
        if(!userMobile){message += ', Teléfono'; result = false}
        if(!userIdentityCard){message += ', Documento de Identidad'; result = false}
        if(!userDateBirthday){message += ', Fecha de Nacimiento'; result = false}
        if(!userAddressStreet){message += ', Calle'; result = false}
        if(!userAddressCity){message += ', Ciudad'; result = false}
        if(!userAddressState){message += ', Estado'; result = false}
        if(!userAddressPostalcode){message += ', Código Postal'; result = false}
        if(!userAddressCountry){message += ', País'; result = false}

        message += '.';
        if(!result){
            openAlert(message,'danger');
            setTimeout( function(){
                closeAlert();
            }, 5000);
        }
        return result
    }

    const createUser = async() => {
        try {
            openAlert('Creando Cuentas','light');

            if(validateData()){
                console.log('Crear datos')
                const data = {
                    name: userName,
                    lastName: userLastName,
                    phone: userMobile,
                    document: userIdentityCard,
                    birthdate: userDateBirthday,
                    street: userAddressStreet,
                    city: userAddressCity,
                    state: userAddressState,
                    postalCode: userAddressPostalcode,
                    country: userAddressCountry,
                    education: userEducation,
                    experience: userExperience,
                    financialInstruments: userFinancialInstruments,
                    riskTolerance: userRiskTolerance,
                    tradingFrequency: userTradingFrequency,
                }
        
                const resp = await createUserInformation(data);
                const { message, body } = resp

                if(message === 'Successful'){

                    dispatch(addAccounts(body));

                    openAlert(t('profile.form.save_success'),'success');
                    setTimeout( function(){
                        closeAlert();
                        navigate(`/`)
                    }, 2000);

                }
                closeAlert();
            };
        } catch (error) {
            openAlert('Error al actualizar Información','danger');
        }

        setTimeout( function(){
            closeAlert();
        }, 2000);
    }


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        setTimeout(() => {
            const alert = document.querySelector('#alert-webtrade');
            alert.classList.remove('show');
        },2000);
    }

    async function changePassword() {

        const alert = document.querySelector('#alert');

        if(oldPassword == newPassword){

            alert.textContent = 'Error! La contraseña nueva debe ser diferente a la contraseña anterior.';
            alert.classList.add('show');
            alert.classList.replace('alert-success', 'alert-danger');
        } else if( newPassword != rnewPassword){

            alert.textContent = 'Error! Las contraseñas no coinciden.';
            alert.classList.add('show');
            alert.classList.replace('alert-success', 'alert-danger');
        } else{

            let data = {password:newPassword, account:store.informacionUsuario.accounts[store.informacionUsuario.role]}

            const rest = await changePasswordApi(data);

            if (rest.message === 'Successful'){
                openAlert(t('profile.form.save_success'),'success');

                closeModal();
                setTimeout( function(){
                    closeAlert();
                }, 2000);
            } else{

                alert.textContent = 'Error! La contraseña debe tener un mínimo de 8 caracteres, debe contener al menos una letra y un número.';
                alert.classList.add('show');
                alert.classList.replace('alert-success', 'alert-danger');
            }
        }
    }

    return (
        <React.Fragment>
            <h2 className="gen-fontwhite"> {t('profile.title')} </h2>
            <hr />
            <div className="row text-start gen-fontwhite">
                <div className="col-lg-4 col-md-12">{informacionUsuario.user}</div>
                <div className="col-lg-4 col-md-12">{t('profile.total_deposited')}<h2>$ {investmentInfoAccount.totalDepositado}</h2></div>
                <div className="col-lg-4 col-md-12">
                    <button className="btn btn-primary" onClick={openModal}> {t('profile.Change_Password')} </button>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={modalStyles}
                        contentLabel="Example Modal"
                        id="App"
                    >
                        <h2 className="gen-input-text"> {t('profile.Change_Password')} </h2>
                        <div>
                            <InputsPassword onChangeFuntion={(datos) => setOldPassword(datos)} 
                            inputId="lastPassword" labelText={t('profile.Change_Password_form.last_password')} />
                            <InputsPassword onChangeFuntion={(datos) => setNewPassword(datos)} 
                            inputId="newPassword" labelText={t('profile.Change_Password_form.new_password')} />
                            <InputsPassword onChangeFuntion={(datos) => setRnewPassword(datos)} 
                            inputId="validateNewPassword" labelText={t('profile.Change_Password_form.validate_new_password')} />
                        </div>
                        <button className="btn btn-primary mt-3 me-1" onClick={() => changePassword()}> {t('profile.Change_Password_form.save')} </button>
                        <button className="btn btn-danger mt-3" onClick={closeModal}> {t('profile.Change_Password_form.close')} </button>
                        <div id="alert" className="alert alert-success fade" role="alert">
                        </div>
                    </Modal>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-lg-12 col-md-12  mb-5">
                    <form>
                        <div className="row text-start">
                            <h4 className="mb-3 mt-3 gen-fontwhite">{t('profile.form.first_title')} </h4>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <label htmlFor="formName" className="form-label gen-fontwhite"> {t('profile.form.name')} </label>
                                <input type="text" className="form-control gen-input-text" id="formName" aria-describedby="nameHelp"  value={userName} onChange={(datos) => setUserName(datos.target.value)} />
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <label htmlFor="formLastName" className="form-label gen-fontwhite"> {t('profile.form.lastname')} </label>
                                <input type="text" className="form-control gen-input-text" id="formLastName" aria-describedby="lastNameHelp" onChange={(datos) => setUserLastName(datos.target.value)} value={userLastName} />
                            </div>
                            <div className="mb-3 col-md-6 col-sm-6">
                                <label htmlFor="formPhone" className="form-label gen-fontwhite"> {t('profile.form.phone')} </label>
                                <input type="text" className="form-control gen-input-text" id="formPhone" aria-describedby="phoneHelp" onChange={(datos) => setUserMobile(datos.target.value)} value={userMobile} />
                            </div>
                            <div className="mb-3 col-md-6 col-sm-6">
                                <label htmlFor="formIdentification" className="form-label gen-fontwhite"> {t('profile.form.identification_document')} </label>
                                <input type="text" className="form-control gen-input-text" id="formIdentification" aria-describedby="identificationHelp" onChange={(datos) => setUserIdentityCard(datos.target.value)} value={userIdentityCard} />
                            </div>
                            <div className="mb-3 col-md-6 col-sm-6 col-ls-2">
                                <label htmlFor="formBirth" className="form-label gen-fontwhite"> {t('profile.form.Date_Birth')} </label>
                                <input type="date" className="form-control gen-input-text" id="formBirth" aria-describedby="birthHelp" onChange={(datos) => setUserDateBirthday(datos.target.value)} value={userDateBirthday} />
                            </div>
                        </div>
                        <div className="row text-start">
                            <h4 className="mb-3 mt-3 gen-fontwhite">{t('profile.form.second_title')} </h4>
                            <div className="mb-3 col-12">
                                <label htmlFor="formStreet" className="form-label gen-fontwhite"> {t('profile.form.Street')} </label>
                                <input type="text" className="form-control gen-input-text" id="formStreet" aria-describedby="streetlHelp" onChange={(datos) => setUserAddressStreet(datos.target.value)} value={userAddressStreet} />
                                <div id="streetlHelp" className="form-text gen-fontwhite"> {t('profile.form.street_description')} </div>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label htmlFor="formCity" className="form-label gen-fontwhite"> {t('profile.form.city')} </label>
                                <input type="text" className="form-control gen-input-text" id="formCity" aria-describedby="cityHelp" onChange={(datos) => setUserAddressCity(datos.target.value)} value={userAddressCity} />
                            </div>
                            <div className="mb-3 col-md-6">
                                <label htmlFor="formEstate" className="form-label gen-fontwhite"> {t('profile.form.estate')} </label>
                                <input type="estextate" className="form-control gen-input-text" id="formEstate" aria-describedby="estateHelp" onChange={(datos) => setUserAddressState(datos.target.value)} value={userAddressState} />
                            </div>
                            <div className="mb-3 col-md-6 col-sm-6">
                                <label htmlFor="formCountry" className="form-label gen-fontwhite"> {t('profile.form.Country')} </label>
                                <select name="formCountry" className="form-select gen-input-text" id="formCountry"  value={userAddressCountry} onChange={(datos) => setUserAddressCountry(datos.target.value)}>
                                    <option key="" value="" > País de Origen </option>
                                    <option key="PE" value="PE" > Peru </option>
                                    <option key="CO" value="CO" > Colombia </option>
                                    <option key="EC" value="EC" > Ecuador </option>
                                    <option key="HN" value="HN" > Honduras </option>
                                    <option key="SV" value="SV" > El Salvador </option>
                                    <option key="NI" value="NI" > Nicaragua </option>
                                    <option key="BO" value="BO" > Bolivia </option>
                                    <option key="CR" value="CR" > Costa Rica </option>
                                    <option key="PA" value="PA" > Panamá </option>
                                    <option key="MX" value="MX" > Mexico </option>
                                </select>
                            </div>
                            <div className="mb-3 col-md-6  col-sm-6" >
                                <label htmlFor="formPostalCode" className="form-label gen-fontwhite"> {t('profile.form.postal_code')} </label>
                                <input type="text" className="form-control gen-input-text" id="formPostalCode" aria-describedby="postalCodeHelp" onChange={(datos) => setUserAddressPostalcode(datos.target.value)} value={userAddressPostalcode} />
                            </div>
                        </div>
                        <div className="row text-start">
                            <h4 className="mb-3 mt-3 gen-fontwhite"> {t('profile.form.third_title')} </h4>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <label htmlFor="formFrequency" className="form-label gen-fontwhite"> {t('profile.form.trading_frequency')} </label>
                                <select name="trading_frequency" className="form-select gen-input-text" id="trading_frequency"  value={userTradingFrequency} onChange={(datos) => setUserTradingFrequency(datos.target.value)}>
                                    <option key="less_than_5_trades" value="less_than_5_trades"> Less than 5 Trades </option> 
                                    <option key="6_20_trades" value="6_20_trades"> 6-20 Trades </option> 
                                    <option key="21_50_trades" value="21_50_trades"> 21-50 Trades </option> 
                                    <option key="more_than_50_trades" value="more_than_50_trades"> More Than 50 Trades </option> 
                                </select>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-6">
                                <label htmlFor="formExperience" className="form-label gen-fontwhite"> {t('profile.form.experience')} </label>
                                <select name="formExperience" className="form-select gen-input-text" id="formExperience"  value={userExperience} onChange={(datos) => setUserExperience(datos.target.value)}>
                                    <option key="0" value="0"> 0 </option> 
                                    <option key="1_3" value="1_3"> 1-3 </option> 
                                    <option key="3_5" value="3_5"> 1-5 </option> 
                                    <option key="5" value="5"> +5 </option> 
                                </select>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-6">
                                <label htmlFor="formFinancialInstruments" className="form-label gen-fontwhite"> {t('profile.form.financial_tools')} </label>
                                <select name="formFinancialInstruments" className="form-select gen-input-text" id="formFinancialInstruments"  value={userFinancialInstruments} onChange={(datos) => setUserFinancialInstruments(datos.target.value)}>
                                    <option key="forex" value="forex"> Forex </option> 
                                    <option key="indices" value="indices"> Indices </option> 
                                    <option key="commodities" value="commodities"> Commodities </option> 
                                    <option key="shares" value="shares"> Shares </option> 
                                </select>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-6">
                                <label htmlFor="formTolerance" className="form-label gen-fontwhite"> {t('profile.form.risk_tolerance')} </label>
                                <select name="formTolerance" className="form-select gen-input-text" id="formTolerance"  value={userRiskTolerance} onChange={(datos) => setUserRiskTolerance(datos.target.value)}>
                                    <option key="very_high" value="very_high"> Very High </option> 
                                    <option key="high" value="high"> High </option> 
                                    <option key="medium" value="medium"> Medium </option> 
                                    <option key="low" value="low"> Low </option> 
                                    <option key="very_low" value="very_low"> Very Low </option> 
                                </select>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-6">
                                <label htmlFor="formEducation" className="form-label gen-fontwhite"> {t('profile.form.education')} </label>
                                <select name="formEducation" className="form-select gen-input-text" id="formEducation"  value={userEducation} onChange={(datos) => setUserEducation(datos.target.value)}>
                                    <option key="high_school" value="high_school"> High School </option> 
                                    <option key="bachelors_degree" value="bachelors_degree"> Bachelors Degree </option> 
                                    <option key="masters_degree" value="masters_degree"> Masters Degree </option> 
                                    <option key="doctorate" value="doctorate"> Doctorate </option> 
                                    <option key="other" value="other"> Other </option> 
                                </select>
                            </div>
                        </div>
                    </form>
                    {cuentas?<button className="btn btn-primary" onClick={() => updateUser()}> {t('profile.form.button_update')} </button>:
                    <button className="btn btn-primary" onClick={() => createUser()}> {t('profile.form.button_create')} </button>}
                </div>
            </div>

        </React.Fragment>
    );
}

export default profile;