import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { closeOperation } from '../../../config/helper'
import { showAlertAccion, closeAlertAccion } from '../../../redux/alert_webtrade';

function Trade({changeSendInfo, selectedItem, useAlert}) {

	const { t } = useTranslation();
    const store = useSelector(store => store);
    const [data, setData] = useState([]);

    const dispatch = useDispatch();
	
	const openAlert = (message,typeAlert) =>{
		dispatch(showAlertAccion({message,typeAlert}));
	}

	const closeAlert = () =>{
		dispatch(closeAlertAccion());
	}

    useEffect(() => {
        obtenerDatos()
    }, [store]);

    const obtenerDatos = () => {
        // aqui comienza verdaderamente la funcion.
        const arrayInicial = store.investmentInfoAccount.trades
        let arrayData = [];

        arrayInicial.forEach(function(elemento, indice, array){
            if( elemento.status ) {
                
                arrayData.push({
                    order:elemento.order,
                    time:elemento.createdAt,
                    volumen:elemento.volumen,
                    type:elemento.operation,
                    size:elemento.size,
                    symbol:elemento.symbol,
                    symbolType:elemento.symbol_type,
                    price:elemento.price,
                    stopLoss:elemento.stop_loss,
                    takeProfit:elemento.take_profit,
                    actualPrice:elemento.actualPrice,
                    // swap:elemento.Swap,
                    profit:elemento.profit
                })
                
            }
        });

        setData(arrayData);
    }

    const closeTrade = async(trade) =>{

        openAlert('Cerrando Operación','light');

        const { order, profit } = trade
        const account = store.informacionUsuario.accounts[store.informacionUsuario.role]
        const { message, error } = await closeOperation({order,account,amount:profit,status:'false'})
        closeAlert();
        if (message === 'Successful'){
            useAlert(t('mt4.close_operation_success'));
        }else{
            useAlert(error);
        }
    }

    const deleteTrade = async(trade) =>{
        const { order } = trade
        const account = store.informacionUsuario.accounts[store.informacionUsuario.role]
        const { message, error } = await closeOperation({order,account,amount:0,status:'delete'})
        if (message === 'Successful'){
            useAlert(t('mt4.close_operation_success'));
        }else{
            useAlert(error);
        }
    }

    return (
        <div className="container mt-3">
            <table className="table table-striped gen-fontwhite">
                <thead>
                    <tr>
                        <th> {t('mt4.order')} </th>
                        <th> {t('mt4.time')} </th>
                        <th> {t('mt4.type')} </th>
                        <th> {t('mt4.size')} </th>
                        <th> {t('mt4.symbol')} </th>
                        <th> {t('mt4.price')} </th>
                        <th> {t('mt4.sl')} </th>
                        <th> {t('mt4.tp')} </th>
                        <th> {t('mt4.actual_price')} </th>
                        {/* <th> {t('mt4.swap')} </th> */}
                        <th> {t('mt4.profit')} </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr className={item.order == selectedItem ? 'trade-selected':'trade-unselected gen-fontwhite'} key={item.order}>
                            <td style={{'cursor':'pointer','color':'black','background':'#c1c1c1'}} className='btn btn-outline-dark ' onClick={() => changeSendInfo({'id':item.order,'symbol':`${item.symbolType}:${item.symbol}`,'stopLoss':item.stopLoss,'takeProfit':item.takeProfit})}>{ item.order }</td>
                            <td >{ item.time }</td>
                            <td >{ item.type }</td>
                            <td >{ item.volumen }</td>
                            <td >{ item.symbol }</td>
                            <td >{ item.price }</td>
                            <td >{ item.stopLoss }</td>
                            <td >{ item.takeProfit }</td>
                            <td >{ item.actualPrice.toFixed(6) }</td>
                            {/* <td >{ item.swap.toFixed(2) }</td> */}
                            <td >{ item.profit.toFixed(6) }</td>
                            <td>
                                <button className='btn btn-danger' onClick={() => closeTrade(item)}>Cerrar</button>
                                {/* <button className='btn btn-danger' onClick={() => deleteTrade(item)}>Delete</button> */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Trade;