import React from 'react';
import { useTranslation } from "react-i18next";

function BloqueoDeCuenta() {
	const { t } = useTranslation();

    const customStyles = {
        // 'backgroundColor':'#E9E7E7',
        'padding':'15px 10px',
        'fontWeight':900,
        'color':'white'
    };

    return (
        <React.Fragment>
            <div className="row" style={customStyles}>
               <div className="col-12">
                    <h3> {t('infoAccount.account_not_demo')} </h3>
               </div>
            </div>
        </React.Fragment>
    );
}

export default BloqueoDeCuenta;